export const getlandingPage = (slug) => {
  return new Promise((resolve, reject) => {
    import(`../../../../fs/efsweb/LandingPages/${slug}.json`)
      .then((landingList) => {
        resolve(landingList.default.filter((landing) => landing.slug === slug)[0])
      })
      .catch((err) => {
        reject(err)
      })
  })
}
