import '@/styles/globals.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-toastify/dist/ReactToastify.css'
// import '@fortawesome/fontawesome-svg-core/styles.css'
// import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Suspense } from 'react'
import dynamic from 'next/dynamic'
import App from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { getRedirect } from '../utils/utils'
import { getlandingPage } from '../data/templates'
import { setCookies } from 'cookies-next'
import { SSRProvider } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import Spinner from 'react-bootstrap/Spinner'

const NewHeader = dynamic(() => import('../components/Common/NewHeader'));
const Footer = dynamic(() => import('../components/Common/Footer'));
const Gtm =  dynamic(() => import('../components/GTMScript'));
export default function MyApp({ Component, pageProps, subdomain, hasQuery=false, hostname, protocol }) {
  const router = useRouter()
  // const [header, setHeader] = useState('');
  // const [footer, setFooter] = useState('');
  const [is404, setIs404] = useState(false);
  const [canonicalUrl, setCanonicalUrl] = useState('');
  let { ui } = router.query

  let header = pageProps?.landing?.custom?.header;
  let footer = pageProps?.landing?.custom?.footer;

  useEffect(() => {
    // setHeader(pageProps?.landing?.custom?.header);
    // setFooter(pageProps?.landing?.custom?.footer);

    setIs404(router.pathname === '/404');

    const host = `${protocol}//${hostname}`;
    const computedCanonicalUrl = router.asPath.split('?').length > 1 
      ? host + router.asPath.split('?')[0] 
      : host + router.asPath.split('#')[0];
    setCanonicalUrl(computedCanonicalUrl);

    require("bootstrap/dist/js/bootstrap.bundle.min.js");
  }, [router.pathname, router.asPath, pageProps, protocol, hostname]);;

  useEffect(() => {
    require("bootstrap/dist/js/bootstrap.bundle.min.js");
  }, []);

  let showHeader = '', showFooter = '';
  if (router.query.ui !== 'no' && header !== false && footer !== false) {
    showHeader = <NewHeader />;
    showFooter = <Footer />;
  }

  if (router.query.utm_campaign || router.query.utm_medium || router.query.utm_source) {
    setCookies('WebHR_Campaign_Info', router.query, {})
  }

  return (
    <>
      <Head>
        {subdomain == "sandbox" && <meta name="robots" content="noindex" />}
        {!is404 && hasQuery && <link rel='canonical' href={canonicalUrl} key="canonical-link" />}
        <meta charSet="utf-8" />
        <meta property="og:title" content="Online HR Software | Global leader in Cloud based HR - trusted by 28,000 Companies in 200 Countries" />
        <meta property="og:type" content="article" />
        <meta property="og:url" content="https://web.hr/" />
        <meta property="og:image" content="https://web.hr/WebHR_OG.png" />
        <meta property="og:description" content="Global leader in Cloud based HR - trusted by 28,000 companies in 200 countries" />
        <meta property="og:site_name" content="WebHR" />
        <meta name="keywords" content="Online HR, HRM, Human Resource, Payroll, WebHR, HRMS, HRM System, Human Resource Management System, HR Software, human resource management software, hr system, Hr Software, Hrm Software, Hr Online, Hr solutions, Hrms, Hris, Payroll Software, Hr Management Software" />
        <meta name="description" content="Global leader in Cloud based HR - trusted by 28,000 companies in 200 countries" />
        <meta name="author" content="WebHR" />
        <meta name="custom1" content="webhr_www" />
        <meta name="copyright" content="© 2004-2020 - WebHR Inc. Developed by Verge Inc." />
        <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
        <meta name="viewport" content="width=device-width" />
        <meta name="theme-color" content="white" />
        <meta name="mobile-web-app-capable" content="yes" />
        <title>Online HR Software | Global leader in Cloud based HR - trusted by 28,000 Companies in 200 Countries</title>
      </Head>
      {/* <Suspense fallback={()=>(<Loading />)}> */}
        <SSRProvider>
            {showHeader}
              <>
                <Gtm/>
                <Component {...pageProps} />
              </>
            {showFooter}
        </SSRProvider>  
      {/* </Suspense> */}
    
    </>
  )
}

MyApp.getInitialProps = async (appContext) => {
  const { source, destination } = getRedirect(appContext.router.asPath)
  const appProps = await App.getInitialProps(appContext)
  const {req} = appContext.ctx;
  let hostname = '';
  let protocol = '';
  if (appContext.ctx.req) {
    protocol = req.headers['x-forwarded-proto'] || (req.connection.encrypted ? 'https' : 'http');
    protocol+=":"
    hostname = appContext.ctx.req.headers.host;
  } else {
    if (typeof window !== 'undefined') {
      protocol = window.location.protocol;
      hostname = window.location.host;
    }
  }
  let {query} = appContext.router;
  let hasQuery = false;
  hasQuery = Object.keys(query).length > 0;
  let subdomain = '';
  const parts = hostname.split('.');
  if (parts.length > 2) {
    subdomain = parts.slice(0, parts.length - 2).join('.');
  }
  let landing = ''
  if (appContext.router.query.Landing !== undefined) {
    landing = await getlandingPage(appContext.router.query.Landing)
  }
  if (source) {
    appContext?.ctx?.res?.writeHead(301, { Location: destination })
    appContext?.ctx?.res?.end()
  }

 

  return { ...appProps, landing, subdomain, hasQuery, hostname, protocol}
}
