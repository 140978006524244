export const getRedirect = (currentPath) => {
  let redirect
  if (currentPath.includes('.php')) {
    if (currentPath.includes('/play') || currentPath.includes('/signupconfirm')) {
      let path = currentPath.split('.')[0]
      let params = currentPath.split('.php')[1]
      redirect = path + params
    } else {
      redirect = currentPath.split('.')[0]
    }
  } else if (currentPath == '/index') {
    redirect = '/'
  } else if (currentPath == '/pk') {
    redirect = '/get/hr-for-pakistan'
  } else if (currentPath == '/signup') {
    redirect = '/pricing'
  } else if (currentPath == '/contents/top-10-benefits-of-llc') {
    redirect = '/contents/advantages-and-disadvantages-of-llc'
  } else if (currentPath == '/glossary/aca') {
    redirect = '/glossary/affordable-care-act'
  } else if (currentPath == '/glossary/partnerwithus') {
    redirect = '/glossary/partner-with-us'
  }else if(currentPath == '/glossary/earned-icome-credit-eic'){
    redirect = '/glossary/earned-income-tax-credit'
  }else if(currentPath == '/glossary/edi'){
    redirect = '/glossary/electronic-data-interchange'
  }else if(currentPath == '/glossary/eft'){
    redirect = '/glossary/electronic-funds-transfer-eft'
  }else if(currentPath == '/glossary/ein'){
    redirect = '/glossary/employer-identification-number'
  }else if(currentPath == '/glossary/absence-management-'){
    redirect = '/glossary/absence-management'
  }else if(currentPath == '/glossary/absenteeism'){
    redirect = '/glossary/absence-management'
  }else if(currentPath == '/glossary/absenteeism-policy'){
    redirect = '/glossary/absence-management'
  }else if(currentPath == '/glossary/accruals-leave'){
    redirect = '/glossary/leave-accruals'
  }else if(currentPath == '/glossary/adp'){
    redirect = '/glossary/actual-deferred-percentage-adp'
  }else if(currentPath == '/glossary/bereavement'){
    redirect = '/glossary/bereavement-leave'
  }else if(currentPath == '/glossary/cpp'){
    redirect = '/glossary/certified-payroll-professional-cpp'
  }else if(currentPath == '/glossary/enps'){
    redirect = '/glossary/employee-net-promoter-score-enps'
  }else if(currentPath == '/contents/emotional-intelligence-in-hr-and-its-relationship-with-hr-leadership'){
    redirect = '/contents/emotional-intelligence-for-hr-at-workplace'
  }else if(currentPath == '/contents/hr-performance-and-emotional-intelligence-'){
    redirect = '/contents/emotional-intelligence-for-hr-at-workplace'
  }else if(currentPath == '/contents/emotional-intelligence-at-workplace-and-hr-performance'){
    redirect = '/contents/emotional-intelligence-for-hr-at-workplace'
  }else if(currentPath == '/contents/key-features-of-hr-software'){
    redirect = '/contents/hrms-software-features'
  }else if(currentPath == '/get/applicant_tracking_system'){
    redirect = '/get/applicant-tracking-system'
  }else if(currentPath == '/get/hr-for-uae'){
    redirect = '/get/best-hr-software-uae'
  }else if(currentPath == '/get/employee-onboarding-software'){
    redirect = '/get/hr-onboarding-software'
  }else if(currentPath == '/get/applicant-tracking-system'){
    redirect = '/get/applicant-tracking-software'
  }else if(currentPath == '/contents/how.flexible.and.responsive.is.hr.automation'){
    redirect = '/contents/how-flexible-and-responsive-is-hr-automation'
  }else if(currentPath == '/glossary/turnover-rate'){
    redirect = '/glossary/employee-turnover'
  }else if(currentPath == '/glossary/human-resources-information-system-hris'){
    redirect = '/glossary/what-is-hris'
  }else if(currentPath == '/contents/hrm-vs-hcm-vs-hris'){
    redirect = '/contents/hrms-vs-hcm-vs-hris'
  }else if(currentPath == '/glossary/human-resources-management-system-hrms'){
    redirect = '/glossary/what-is-hrms'
  }else if(currentPath == '/glossary/human-capital-management-hcm'){
    redirect = '/glossary/what-is-hcm-software'
  }else if(currentPath == '/glossary/appointment.letter'){
    redirect = '/glossary/appointment-letter'
  }else if(currentPath == '/glossary/dearness.allowance'){
    redirect = '/glossary/dearness-allowance'
  }else if(currentPath == '/glossary/hr-software'){
    redirect = '/glossary/what-is-hr-software'
  }else if(currentPath == '/glossary/ats'){
    redirect = '/glossary/what-is-ats'
  }else if(currentPath == '/glossary/fringe-benefits-'){
    redirect = '/glossary/fringe-benefits'
  }else if(currentPath == '/get/Social-HR-Software'){
    redirect = 'https://web.hr/'
  }

  if (redirect === undefined) return { source: null, destination: null }
  return { source: currentPath, destination: redirect, permanent: true }
}

export const CalculateWebHRPricing = (iEmployees, iPaymentTerm) => {
  var dCost = 0
  var iStep = 5
  var iStepDecrementFactor = 0,
    price = 0
  var lbound = '0',
    ubound = '0'
  if (iPaymentTerm == 0) var pricing = { 5: 3.0, 1000: 2.0 }
  else var pricing = { 5: 2.0, 1000: 1.0 }
  lbound = '5'
  ubound = '1000'
  iStepDecrementFactor = 0
  return {
    employees: iEmployees,
    price: pricing[lbound] - ((iEmployees - lbound) / iStep) * iStepDecrementFactor,
  }
}

export const CalculateStorage = (iEmployees) => {
  iEmployees = parseInt(iEmployees)
  let dDocumentStorage = iEmployees * 100
  let sDocumentStorage = '500 MB'
  if (dDocumentStorage > 1024) sDocumentStorage = (dDocumentStorage / 1024).toFixed(2) + ' GB'
  else sDocumentStorage = dDocumentStorage + ' MB'

  return sDocumentStorage
}
